<template>
  <div class="window-live-item"
       ref="big_video"
       @dblclick="fullScreen = !fullScreen">
    <div class="top bar">
      <div class="circle"
           :style="getCircleStyles(config)"></div>
      <span>{{ config && config.is_publish ? "直播中" : "空闲" }}-{{config.name}} {{ config.DRONEMODEL }}</span>
    </div>
    <div class="live">
      <!-- <video v-if="config && config.isLive" src></video> -->
      <!-- <div v-if="config.is_online && config.is_publish"
           style="width: 100%; height: 100%"
           ref="liveVideo"
           id="bigLive"
           @click.capture.stop="" />
    </div> -->
      <div v-if="config.is_online && config.is_publish"
           style="width: 100%; height: 100%">
        <!-- tcplayer容器 -->
        <div class="video"
             id="bigLive"
             v-if="$online"
             style="
            background-color: #333333;
            width: 100%;
            height: 100%;
            aspect-ratio: 16/9;
          "></div>
        <!--liveqing的iframe-->
        <div v-else-if="liveNvrUrl != ''"
             style="width: 100%; height: 100%; aspect-ratio: 16/9">
          <iframe :src="liveNvrUrl"
                  width="100%"
                  height="100%"
                  allowfullscreen
                  allow="autoplay; fullscreen"></iframe>
        </div>
        <!-- flv.js容器 -->
        <video class="video"
               id="bigLive"
               autoplay
               v-else
               style="
            object-fit: contain;
            width: 100%;
            width: 100%;
            height: 100%;
            background-color: #7d7d7d;
            aspect-ratio: 16/9;
          "></video>
      </div>
      <!-- <div v-else>
        <video v-if="config.is_online && config.is_publish"
               id="bigLive"
               autoplay
               style="
          width: 100%;
          height: 100%;
          object-fit: contain;
          background-color: #242426;
        "
               @click.capture.stop=""></video>
      </div> -->

      <div class="bottom bar"
           v-show="showBottom">
        <template v-if="config && config.is_online">
          <!-- <span class="right-item">
          <img :src="icons.drone" alt />
          <span>{{ config.DRONEMODEL }}</span>
        </span> -->
          <!-- <span class="right-item">
          <img :src="icons.time" alt />
          <span>{{ config.create_time }}</span>
        </span> -->
          <span class="right-item">
            <img :src="icons.person"
                 alt />
            <span>{{ config.name }}</span>
          </span>
        </template>
      </div>
      <img class="FullscreenIcon"
           :src="fullScreen ? videoScreenRestore : videoFullscreen"
           alt=""
           @click="fullScreen = !fullScreen" />
    </div>
  </div>
</template>

<script>
import drone from '@/assets/img/statistics/drone.png';
// import not_live from "@/assets/img/statistics/not_live.png";
import LiveTimeWhite from '@/assets/img/statistics/time-white.png';
import PersonnelWhite from '@/assets/img/statistics/personnel-white.png';
import Api from '@/utils/api.js';
import videoFullscreen from '@/assets/img/statistics/videoFullscreen.png';
import videoScreenRestore from '@/assets/img/statistics/videoScreenRestore.png';

export default {
  name: 'window-live-item',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    showBottom: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  watch: {
    config: {
      immediate: true,
      deep: true,
      handler: function (val) {
        if (val.is_publish === 1) {
          this.init();
          // console.log(this.playerId);
        }
        // console.log("BIG live item:", val);
      },
    },
    fullScreen(status) {
      // console.log(status);
      // console.log(document.exitFullscreen);
      // console.log(document.fullscreenElement);
      if (status) {
        let ele = this.$refs.big_video;
        // var ele=document.getElementById('live-video');
        if (ele.requestFullscreen) {
          ele.requestFullscreen();
        } else if (ele.mozRequestFullScreen) {
          ele.mozRequestFullScreen();
        } else if (ele.webkitRequestFullScreen) {
          ele.webkitRequestFullScreen();
        }
      } else {
        let de = document;
        if (de.exitFullscreen) {
          de.exitFullscreen();
        } else if (de.mozCancelFullScreen) {
          de.mozCancelFullScreen();
        } else if (de.webkitCancelFullScreen) {
          de.webkitCancelFullScreen();
        }
      }
    },
  },
  mounted() {
    var _this = this;
    document.addEventListener('fullscreenchange', function () {
      if (document.fullscreenElement != null) {
        console.info('Went full screen');
        _this.fullScreen = true;
      } else {
        console.info('Exited full screen');
        _this.fullScreen = false;
      }
    });
  },
  data() {
    return {
      player: null,
      icons: {
        drone,
        time: LiveTimeWhite,
        person: PersonnelWhite,
        // not_live: not_live,
      },
      playerId: 'bigLive',
      fullScreen: false,
      videoFullscreen,
      videoScreenRestore,
      liveNvrUrl: '',
    };
  },
  methods: {
    getCircleStyles(item) {
      return {
        backgroundColor: item && item.is_publish ? '#32e225' : '#D3D3D3',
      };
    },
    // 初始化video
    init() {
      // var _this = this;
      // console.log(this.config);
      let url = Api.getLiveUrl();
      if (!(this.config.IsPublish || this.config.is_publish)) {
        return;
      }

      if (!this.$online) {
        url = Api.getLiveqingUrl();
        if (this.$CSTJ) {
          url = Api.getLiveNvr();
        }
      }
      this.$post(url, {
        client: 'web',
        user_id: this.config.user_id,
        channel: 1,
      })
        .then((res) => {
          if (res.data) {
            if (this.$online) {
              // ======tcplayer
              if (this.player !== null) {
                this.player.destroy();
                //   this.player.videoClear();
                this.player = null;
              }
              if (this.$public) {
                this.player = new TcPlayer('bigLive', {
                  // "m3u8": res.data.m3u8_url,

                  flv: this.$public ? res.data.UDP_url : res.data.flv_url,
                  webrtc: res.data.UDP_url,
                  // flv: this.$public ? res.data.UDP_url : res.data.flv_url,
                  // h5_flv: this.$public ? false : true,
                  //   rtmp: res.data.default_url,
                  live: true,
                  //   controls: 'none',
                  volume: 0,
                  autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
                  width: '100%', //视频的显示宽度
                  height: '100%', //视频的显示高度
                  listener: function (msg) {
                    if (msg.type == 'error') {
                      switch (msg.detail.code) {
                        case 1:
                          _this.$message.error(
                            '网络错误，请检查网络配置或者播放链接是否正确。'
                          );
                          break;
                        case 2:
                          _this.$message.error('视频格式 Web 播放器无法解码。');
                          break;
                        case 3:
                          _this.$message.error('视频解码错误。');
                          break;
                        case 4:
                          _this.$message.error(
                            '当前系统环境不支持播放该视频格式。'
                          );
                          break;
                        case 5:
                          _this.$message.error(
                            '播放器判断当前浏览器环境不支持播放传入的视频，可能是当前浏览器不支持 MSE 或者 Flash 插件未启用。'
                          );
                          break;
                        case 13:
                          _this.$message.error('直播已结束，请稍后再来。');
                          break;
                        case 1001:
                          _this.$message.error('断网了');
                          break;
                        case 1002:
                          _this.$message.error(
                            '获取视频失败，请检查播放链接是否有效。'
                          );
                          break;
                        case 2048:
                          _this.$message.error(
                            '无法加载视频文件，跨域访问被拒绝。'
                          );
                          break;
                        default:
                          _this.$message.error('出错了');
                          break;
                      }
                    }

                    //   if (msg.type == 'pause') {
                    //     console.log('pause');
                    //   }
                    if (msg.type == 'ended') {
                      if (_this.independent) {
                        _this.$Message.info('小窗直播已结束');
                        _this.player.destroy();
                        _this.player = null;
                        _this.$_bus.$emit('endLive');
                      }
                    }
                    //   if (msg.type == 'seeking') {
                    //     console.log('seeking');
                    //   }
                    //   if (msg.type == 'seeked') {
                    //     console.log('seeked');
                    //   }
                    //   if (msg.type == 'resize') {
                    //     console.log('resize');
                    //   }
                  },
                });
              } else {
                this.player = new TcPlayer('bigLive', {
                  // "m3u8": res.data.m3u8_url,

                  // flv: this.$public ? res.data.UDP_url : res.data.flv_url,
                  // webrtc: res.data.UDP_url,
                  flv: this.$public ? res.data.UDP_url : res.data.flv_url,
                  h5_flv: this.$public ? false : true,
                  //   rtmp: res.data.default_url,
                  live: true,
                  //   controls: 'none',
                  volume: 0,
                  autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
                  width: '100%', //视频的显示宽度
                  height: '100%', //视频的显示高度
                  listener: function (msg) {
                    if (msg.type == 'error') {
                      switch (msg.detail.code) {
                        case 1:
                          _this.$message.error(
                            '网络错误，请检查网络配置或者播放链接是否正确。'
                          );
                          break;
                        case 2:
                          _this.$message.error('视频格式 Web 播放器无法解码。');
                          break;
                        case 3:
                          _this.$message.error('视频解码错误。');
                          break;
                        case 4:
                          _this.$message.error(
                            '当前系统环境不支持播放该视频格式。'
                          );
                          break;
                        case 5:
                          _this.$message.error(
                            '播放器判断当前浏览器环境不支持播放传入的视频，可能是当前浏览器不支持 MSE 或者 Flash 插件未启用。'
                          );
                          break;
                        case 13:
                          _this.$message.error('直播已结束，请稍后再来。');
                          break;
                        case 1001:
                          _this.$message.error('断网了');
                          break;
                        case 1002:
                          _this.$message.error(
                            '获取视频失败，请检查播放链接是否有效。'
                          );
                          break;
                        case 2048:
                          _this.$message.error(
                            '无法加载视频文件，跨域访问被拒绝。'
                          );
                          break;
                        default:
                          _this.$message.error('出错了');
                          break;
                      }
                    }

                    //   if (msg.type == 'pause') {
                    //     console.log('pause');
                    //   }
                    if (msg.type == 'ended') {
                      if (_this.independent) {
                        _this.$Message.info('小窗直播已结束');
                        _this.player.destroy();
                        _this.player = null;
                        _this.$_bus.$emit('endLive');
                      }
                    }
                    //   if (msg.type == 'seeking') {
                    //     console.log('seeking');
                    //   }
                    //   if (msg.type == 'seeked') {
                    //     console.log('seeked');
                    //   }
                    //   if (msg.type == 'resize') {
                    //     console.log('resize');
                    //   }
                  },
                });
              }

              // ========ckplayer
              // let videoObject = {
              //   container: "#" + this.playerId, //容器的ID或className
              //   variable: "player", //播放函数名称
              //   autoplay: true,
              //   live: true,
              //   video: res.data.default_url,
              //   loaded: "loadedHandler", //当播放器加载后执行的函数
              // };
              // this.player = new ckplayer(videoObject);
              /////
            } else {
              // $CSTJ
              this.liveNvrUrl =
                'http://43.4.201.100:10800/play.html?channel=' +
                res.data.channel +
                '&iframe=yes&aspect=741x420';

              ///////
              // ========flv.js
              // if (this.player !== null) {
              //   this.player.pause();
              //   this.player.unload();
              //   this.player.detachMediaElement();
              //   this.player.destroy();
              //   this.player = null;
              // }
              // this.$nextTick(() => {
              //   if (flvjs.isSupported()) {
              //     this.player = flvjs.createPlayer({
              //       type: 'flv',
              //       url: res.data.flv_url,
              //       isLive: true,
              //     });
              //     this.player.attachMediaElement(
              //       document.getElementById('bigLive')
              //     );
              //     this.player.load();
              //     this.player.play();
              //   } else {
              //     this.$message.error('不支持flv格式');
              //     return;
              //   }
              // });
              ///////////
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notice.error({
            title: '获取直播地址失败,返回无数据',
            desc: err.toString(),
          });
        })
        .finally(() => {
          this.spinShow = false;
        });
    },
  },
  beforeDestroy() {
    if (this.$online || this.liveNvrUrl != '') {
      this.player.destroy();
      this.player = null;
    } else {
      this.player.pause();
      this.player.unload();
      this.player.detachMediaElement();
      ////
      this.player.destroy();
      this.player = null;
      /////
      //   this.player.videoClear();
    }
  },
};
</script>

<style lang="scss" scoped>
.window-live-item {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #7d7d7d;
  .live {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    video {
      width: 100%;
      height: 100%;
    }
  }
  .bar {
    color: #fff;
    width: 100%;
    position: absolute;
    left: 0;
    height: 0.36rem;
    max-height: 0.36rem;
    // background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    padding: 0 0.2rem;
  }
  .top {
    z-index: 1;
    top: 0;
    .circle {
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
      margin-right: 0.1rem;
    }
  }
  .bottom {
    bottom: 0;
    // justify-content: space-between;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #555;
    .right-item {
      display: flex;
      align-items: center;
      flex: 1;
      &:last-child {
        flex: 0 0 1rem;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img {
        width: 0.16rem;
        height: 0.16rem;
        margin-right: 0.2rem;
      }
    }
  }
  .FullscreenIcon {
    position: absolute;
    top: 30px;
    right: 10px;
    width: 30px;
    height: auto;
    opacity: 0;
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
  }
}
.window-live-item:hover {
  .FullscreenIcon {
    opacity: 1;
  }
}
</style>
