<template>
  <div class="window-live-item">
    <div class="top bar">
      <div class="circle" :style="getCircleStyles(config)"></div>
      <span
        >{{ config && config.is_publish ? '直播中' : '空闲' }}-{{
          config.name
        }}
        {{ config.DRONEMODEL }}</span
      >
      <Button class="switchUrl" type="primary" @click="switchU" v-show="$public">{{
        switchUrl === 1 ? '默认' : '低延迟'
      }}</Button>
    </div>
    <div class="live">
      <!-- <video v-if="config && config.isLive" src></video> -->
      <VideoItem
        :config="config"
        :isWatch="true"
        :showCtrl="showCtrl"
        :channel="channel"
        :switchUrl="switchUrl"
        v-show="config.is_online && config.is_publish"
        ref="liveVideo"
      />
      <!-- <img v-else :src="icons.not_live" alt /> -->

      <div class="bottom bar" v-show="showBottom">
        <template v-if="config && config.is_online">
          <span class="right-item">
            <!--<img :src="icons.drone" alt />-->
            <!--<span>{{ config.DRONEMODEL }}</span>-->
          </span>
          <!-- <span class="right-item">
          <img :src="icons.time" alt />
          <span>{{ config.create_time }}</span>
        </span> -->
          <span class="right-item">
            <img :src="icons.person" alt />
            <span>{{ config.name }}</span>
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import drone from '@/assets/img/statistics/drone.png'
// import not_live from "@/assets/img/statistics/not_live.png";
import LiveTimeWhite from '@/assets/img/statistics/time-white.png'
import PersonnelWhite from '@/assets/img/statistics/personnel-white.png'
import VideoItem from '../watch/video-item.vue'
import Api from '@/utils/api.js'
export default {
  name: 'window-live-item',
  components: { VideoItem },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    showBottom: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    showCtrl: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    channel: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    config: {
      immediate: true,
      deep: true,
      handler: function (val) {
        // console.log('live item:', val)
      },
    },
  },
  data() {
    return {
      icons: {
        drone,
        time: LiveTimeWhite,
        person: PersonnelWhite,
        // not_live: not_live,
      },
      switchUrl: 1, //控制直播延迟变量
      live: null, //直播状态
    }
  },
  methods: {
    getCircleStyles(item) {
      return {
        backgroundColor: item && item.is_publish ? '#32e225' : '#D3D3D3',
      }
    },
    remoteControlHandler(type, val) {
      let parems = {
        dataType: type,
        fromId: JSON.parse(sessionStorage.getItem('user_info')).user_id,
        frpmName: JSON.parse(sessionStorage.getItem('user_info')).name,
        pX: 0,
        pY: 0,
        targetId: this.config.user_id,
        toName: this.config.name,
      }
      if (val == 'up') {
        parems.pY = 2
      } else if (val == 'down') {
        parems.pY = -2
      } else if (val == 'left') {
        parems.pX = -2
      } else if (val == 'right') {
        parems.pX = 2
      }
      this.$post(Api.controlPTZ(), parems)
        .then((res) => {
          //   this.$Message.success(res.msg_customer);
        })
        .catch((err) => {
          this.$Message.error(err)
        })
    },
    //切换低延迟高延迟
    switchU() {
      this.switchUrl = this.switchUrl === 1 ? 2 : 1
      // console.log('this.switchUrl',this.switchUrl);
      this.$refs.liveVideo.init(this.switchUrl)
    },
  },
}
</script>

<style lang="scss" scoped>
.window-live-item {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #7d7d7d;
  //   background-color: $xf_hui4;
  .live {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    video {
      width: 100%;
      height: 100%;
    }
    // img {
    //   width: 1.24rem;
    //   height: 1.12rem;
    // }
    .four-direction {
      width: 1.12rem;
      height: 1.12rem;
      border-radius: 50%;
      background-color: #212121;
      position: absolute;
      right: 5%;
      bottom: 10%;
      opacity: 0;
      z-index: 100;
      transition: all 0.5s;
      .center-circle {
        background-color: #afafaf;
        top: 0.56rem;
        left: 0.56rem;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        position: absolute;
        margin-left: -0.2rem;
        margin-top: -0.2rem;
        z-index: 5;
      }
      .center-line {
        width: 1.12rem;
        height: 0.05rem;
        margin-top: -0.02rem;
        background-color: #000;
        position: absolute;
        top: 0.56rem;
        left: 0;
      }
      .rotate-clockwise {
        transform: rotate(45deg);
      }
      .rotate-anti-clockwise {
        transform: rotate(-45deg);
      }
      .control-direction-arrow {
        color: #afafaf;
        width: 0.48rem;
        height: 0.48rem;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
      }
      .icon-top {
        top: 0.18rem;
        margin-top: -0.24rem;
        left: 0.56rem;
        margin-left: -0.24rem;
      }
      .icon-bottom {
        bottom: 0.18rem;
        margin-bottom: -0.24rem;
        left: 0.56rem;
        margin-left: -0.24rem;
        i {
          transform: rotate(180deg);
        }
      }
      .icon-right {
        top: 0.56rem;
        margin-top: -0.24rem;
        right: 0.18rem;
        margin-right: -0.24rem;
        i {
          transform: rotate(90deg);
        }
      }
      .icon-left {
        top: 0.56rem;
        margin-top: -0.24rem;
        left: 0.18rem;
        margin-left: -0.24rem;
        i {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .bar {
    color: #fff;
    width: 100%;
    position: absolute;
    left: 0;
    height: 0.36rem;
    max-height: 0.36rem;
    // background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    padding: 0 0.2rem;
  }
  .top {
    z-index: 1;
    top: 0;
    .circle {
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
      margin-right: 0.1rem;
    }
    .switchUrl {
      height: 20px;
      margin-left: 10px;
      font-size: 0.14rem;
    }
  }
  .bottom {
    bottom: 0;
    justify-content: space-between;
    display: flex;
    align-items: center;
    background-color: #555;
    .right-item {
      display: flex;
      align-items: center;
      flex: 1;

      &:last-child {
        flex: 0 0 1rem;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img {
        width: 0.16rem;
        height: 0.16rem;
        margin-right: 0.2rem;
      }
    }
  }
}
</style>
