<template>
  <div class="live">
    <div class="left">
      <fullscreen :fullscreen.sync="fullscreen"
                  style="width: 100%; height: 100%">
        <WindowLives :config="windowConfig"
                     @setActiveBigLive="setActiveBigLive" />
      </fullscreen>
    </div>
    <div class="right">
      <LiveLists ref="liveLists"
                 :list="allLiveList"
                 @sendSocketMsg="sendSocketMsg"
                 @changePageState="changePageState"
                 @chooseOneLive="chooseOneLive"
                 @initData="initData" ></LiveLists>
    </div>

    <Spin size="large"
          fix
          v-if="spinShow"></Spin>
    <!-- <Button @click="toggle"
            type="info"
            class="fullscreen">全屏显示</Button> -->
  </div>
</template>

<script>
import WindowLives from './window-lives.vue';
import LiveLists from './live-lists.vue';
import Api from '@/utils/api.js';
import Socket from '@/utils/socket.mixin.js';
import _ from 'lodash';

import fullscreen from 'vue-fullscreen';
import Vue from 'vue';
Vue.use(fullscreen);

export default {
  name: 'live',
  mixins: [Socket],
  components: { WindowLives, LiveLists },
  data() {
    return {
      windowConfig: {
        pageType: 'four',
        fourLiveList: [],
        liveList: [],
        activeBigLive: {},
      },
      spinShow: true,
      hadChoosedCount: 0,
      allList: [],
      allLiveList: [],
      pageType: 'four',
      fullscreen: false,
    };
  },
  mounted() {
    this.user_info = JSON.parse(sessionStorage.getItem('user_info') || '{}');
    if (sessionStorage.getItem('team_id') === null) {
      if (this.user_info.permission == 0) {
        // 如果公版需要团队单选功能,就注释该判断,只保留this.initData(this.user_info.team_id);,否则初始化下无法看到全部正在直播.()
        this.initData(this.user_info.team_id);
      } else {
        this.initData(-1);
      }
    } else {
      this.initData(Number(sessionStorage.getItem('team_id')));
    }
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.initData(value);
    });
  },
  computed: {
    // 4个格子
    isFour() {
      return this.pageType === 'four';
    },
    // 9宫格
    isNine() {
      return this.pageType === 'nine';
    },
    // 大屏显示
    isBig() {
      return this.pageType === 'big';
    },
    // 超管角色
    // isSuperAdmin() {
    //   return (
    //     JSON.parse(window.sessionStorage.getItem('user_info') || '{}').type ===
    //     0
    //   );
    // },
  },
  methods: {
    // socket发送消息
    sendSocketMsg(data) {
      console.log(data);
      this.sendMessage(data);
      this.$notice.warning({
        title: '消息发送成功!',
      });
    },
    // 选择一个视频填充进4个格子 4个格子的内容按照 总选择的数量 % 4 的顺序选取 选中已选的不做处理
    chooseOneLive(item) {
      // console.log(item, this.windowConfig.fourLiveList);
      // console.log(
      //   this.windowConfig.fourLiveList.findIndex(
      //     (ele) => ele.user_id === item.user_id
      //   )
      // );
      if (
        this.windowConfig.fourLiveList.findIndex(
          (ele) => ele.user_id === item.user_id
        ) !== -1
      )
        return;
      let index = this.hadChoosedCount++ % 4;
      item.isWatching = true;
      if (this.windowConfig.fourLiveList.length < 4) {
        this.windowConfig.fourLiveList.push(item);
      } else {
        this.windowConfig.fourLiveList[index].isWatching = false;
        this.windowConfig.fourLiveList.splice(index, 1, item);
      }
      // this.windowConfig.liveList.forEach(ele => {
      //   if (this.windowConfig.fourLiveList.some(e => e.user_id === ele.user_id) === -1) {
      //     ele.isWatching = false
      //   }
      // })
    },
    // 更新在线直播的无人机
    updateOnLineUsers() {
      // console.log('更新在线直播的无人机', this.allList);
      this.allLiveList = this.allList.filter(
        (ele) => ele.is_online && ele.is_publish
      );
      console.log('哪几个人在直播----------------------', this.allLiveList);
      this.windowConfig.liveList = this.allLiveList;
      // allLiveList 中 是否有fourLiveList中的项
      if (this.isFour) {
        this.windowConfig.fourLiveList.forEach((el, index) => {
          if (
            this.allLiveList.findIndex((ele) => ele.user_id === el.user_id) ==
            -1
          ) {
            // allLiveList不存在fourLiveList的内容,直播已终止
            this.windowConfig.fourLiveList[index].isWatching = false;
            this.windowConfig.fourLiveList.splice(index, 1);
          }
        });
      }
    },
    // 更新位置信息 这里只做在线的状态更新
    updateEleLocationInfo(ele, data) {
      ele.is_online = 1;
      ele.is_publish = data.IsPublish;
      ele.head_icon_path = data.head_icon_path;

      //   console.log(
      //     '这个用用户在直播-----------------------------------------------',
      //     ele
      //   );
      this.updateOnLineUsers();
    },
    // 更新在线信息
    updateOnLineInfo(ele, data) {
      ele.is_online = 0;
      this.updateOnLineUsers();
    },
    // 更新位置信息
    updateLocation(data) {
      try {
        this.allList.forEach((ele) => {
          if (
            ele.user_id &&
            data.uid &&
            ele.user_id.toString() === data.uid.toString()
          ) {
            this.updateEleLocationInfo(ele, data);
          }
        });
      } catch (e) {
        console.log(e);
        this.$Notice.success({
          title: '更新位置失败!',
          desc: e.toString(),
        });
      }
    },
    //  更新在线
    updateOnLine(data) {
      try {
        this.allList.forEach((ele) => {
          if (
            ele.user_id &&
            data.uid &&
            ele.user_id.toString() === data.uid.toString()
          ) {
            this.updateOnLineInfo(ele, data);
          }
        });
      } catch (e) {
        console.log(e);
        this.$Notice.success({
          title: '更新在线状态失败!',
          desc: e.toString(),
        });
      }
    },
    //  更新用户信息
    updateMsgInfo(ele, data) {
      if (!this.$refs['liveLists'].showChat) {
        // ele.hadNewMsg = 1;
        ele.NewMsg++;
      } else {
        ele.NewMsg = 0;
      }
      if (data.from_id.toString() !== this.user_info.user_id.toString()) {
        ele.msgList.push({
          avatar:
            data.from_id.toString() === this.user_info.user_id.toString()
              ? this.user_info.head_icon
              : ele.head_icon_path,
          role:
            data.from_id.toString() === this.user_info.user_id.toString()
              ? 'self'
              : 'remote',
          msg_type: data.msg_type || '',
          content: data.msg_content,
          isPlayingVoice: false,
          voiceTimeLength: 0,
          voiceId: ele.msgList.length - 1,
        });
      }
      this.updateOnLineUsers();
    },
    updateMsg(data) {
      try {
        this.allList.forEach((ele) => {
          if (
            ele.user_id &&
            data.data[0].from_id &&
            ele.user_id.toString() === data.data[0].from_id.toString()
          ) {
            this.updateMsgInfo(ele, data.data[0]);
          }
        });
      } catch (e) {
        console.log(e);
        this.$Notice.success({
          title: '更新消息失败!',
          desc: e.toString(),
        });
      }
    },
    // 总的socket处理函数
    // 处理ws消息
    socketMsgHandler(data) {
      let type = data.type || data.msg;
      this.updateLocation(data);
      switch (type) {
        case 'location':
          this.updateLocation(data);
          break;
        // case 'user_msg':
        //   this.updateMsg(data);
        //   break;
        case 'kick_off':
          this.updateOnLine(data);
          break;
        case 'location_logout':
          this.updateOnLine(data);
          break;
        case 'off_line':
          this.updateOnLine(data);
          break;
        default:
          return;
      }
    },

    // 连接socket
    connectSocket() {
      let param = {};
      const url = Api.getWebSocketUrl() + this.userInfo.user_id;
      this.connectWebsocket(url, param, this.socketMsgHandler);
    },
    // 初始化获取一个包含所有无人机的数组
    initData(id) {
      this.windowConfig.fourLiveList = [];
      this.$post(Api.getLiveAdminList(), {
        team_id: id,
      })
        .then((res) => {
          //   console.log(res);
          if (res.data) {
            res.data.map((ele) => {
              ele.isWatching = false;
              ele.autoPlayLive = false;
              ele.DRONEMODEL =
                (ele.detail && ele.detail[0] && ele.detail[0].DRONEMODEL) || '';
              ele.create_time =
                (ele.detail && ele.detail[0] && ele.detail[0].create_time) ||
                '';
              //   ele.hadNewMsg = 0;
              ele.NewMsg = 0;
              ele.msgList = [];
              ele.head_icon_path = '';
              return ele;
            });
            this.allList = res.data.filter((e) => e.user_id != 1);
            console.log('allist------------->>>>>>>>>', this.allList);
            this.$nextTick(() => {
              this.updateOnLineUsers();
              this.connectSocket(); //临时屏蔽Socket连接
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notice.error({
            title: '获取直播列表接口异常,返回无数据',
            desc: err.toString(),
          });
        })
        .finally(() => {
          this.spinShow = false;
        });
    },
    // 切换 4 9 大屏
    changePageState(type) {
      if (type !== 'all') {
        this.pageType = type;
        this.windowConfig.pageType = type;
      }
      if (type === 'nine' || type === 'big') {
        this.windowConfig.liveList = this.allLiveList;
      } else if (type === 'all') {
        this.fullscreen = !this.fullscreen;
      }
    },
    // 大屏直播选择一个当做当前
    setActiveBigLive(item) {
      this.windowConfig.activeBigLive = item;
    },
    ///
    // toggle() {
    //   this.fullscreen = !this.fullscreen;
    // },
  },
};
</script>

<style lang="scss" scoped>
.live {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  position: relative;
  .nine-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .left {
    flex: 1;
    background-color: $black;
    padding: 0.15rem;
    width: calc(100% - 3.34rem);
  }
  .right {
    width: 3.34rem;
    background-color: $xf_hui1;
    font-size: 0.16rem;
    color: $white;
  }
  .little-right {
    padding-top: 0.36rem;
    flex: 0 0 0.36rem;
    background-color: $black;
    flex-direction: column;
    justify-content: flex-start;
    img {
      margin-bottom: 0.2rem;
      cursor: pointer;
      width: 0.14rem;
      height: 0.14rem;
    }
  }
  // .fullscreen {
  //   position: absolute;
  //   top: 62px;
  //   right: 10px;
  // }
}
</style>
