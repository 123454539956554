<template>
  <div class="live-lists">
    <div class="control">
      <div class="nine"
           style="margin-bottom:0.46rem"
           @click="setPageState('all')">
        <img :src="icons.all"
             alt="全屏"
             class="img" />
      </div>
      <div :class="['nine',pageType=='four'?'select':'']"
           @click="setPageState('four')">
        <img :src="icons.four"
             alt="四宫格"
             class="img" />
      </div>
      <div :class="['nine',pageType=='nine'?'select':'']"
           @click="setPageState('nine')">
        <img :src="icons.nine"
             alt="九宫格"
             class="img" />
      </div>
      <div :class="['nine',pageType=='big'?'select':'']"
           @click="setPageState('big')">
        <img :src="icons.big"
             alt="大屏监控"
             class="img" />
      </div>

      <!-- <div class="remote shadow-wrapper">
          <span>远程控制</span>
          <i-switch true-color="#32E225" size="small" v-model="switch1" @on-change="change" >
            <Switch />
          </i-switch>
        </div> -->
    </div>
    <div class="right-box">
      <div :class="$CSXF?'toggle':'toggle blur'">
        <div class="disactive"
             @click="$router.push('/live/watch-lists');">指挥</div>
        <div class="active">直播</div>
      </div>

      <!-- 直播人员列表 -->
      <div class="list">
        <div :class="$CSXF?'item':'item blur'"
             v-for="(item, index) in list"
             :key="index"
             @click.stop="addLiveList(item)">
          <div class="left">
            <img :src="item.head_icon_path" />
          </div>
          <div class="right">
            <p>{{ item.name }}</p>
            <span v-show="pageType == 'four'"
                  :style="getCircleStyles(item).css">{{ getCircleStyles(item).text }}</span>
          </div>
          <!-- <span class="head right-item">{{
            (item.detail && item.detail[0] && item.detail[0].DRONEMODEL) || ""
          }}</span>
          <span class="right-item">
            <img :src="icons.time" alt="" />
            <span>{{
              (item.detail && item.detail[0] && item.detail[0].create_time) ||
              ""
            }}</span>
          </span> -->
          <!-- <div class="msg-right" @click.stop="openChat(item)">
          <img :src="icons.msg" alt="" />
          <div v-if="item.hadNewMsg" class="red-msg"></div>
        </div> -->
        </div>
        <div class="no-data"
             v-if="!list.length">
          <img :src="noData"
               alt="">
          <p>暂无直播</p>
        </div>
      </div>
    </div>
    <!-- <ChatModal @cancel="cancelHandle"
               :showModal="showChat"
               :operations="chatModelOperations"
               :info="liveInfo"
               @sendSocketMsg="sendSocketMsg" /> -->
  </div>
</template>

<script>
// import LiveTime from '@/assets/img/statistics/live-time.png';
// import Personnel from "@/assets/img/statistics/personnel.png";
import big from '@/assets/img/statistics/big.png';
import four from '@/assets/img/statistics/four.png';
import nine from '@/assets/img/statistics/nine.png';
import all from '@/assets/img/statistics/live_full.png';
import noData from '@/assets/img/noData.png';

// import big_select from '@/assets/img/statistics/big_press.png';
// import four_select from '@/assets/img/statistics/four_press.png';
// import nine_select from '@/assets/img/statistics/nine_press.png';
// import Msg from '@/assets/img/statistics/message.png';
// import ChatModal from '../watch/chat-modal.vue';

export default {
  name: 'live-lists',
  //   components: {
  //     ChatModal,
  //   },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chatModelOperations: ['chat'],
      icons: {
        // time: LiveTime,
        // person: Personnel,
        // msg: Msg,
        big,
        four,
        nine,
        all,
      },
      noData,
      showChat: false,
      liveInfo: {},
      pageType: 'four', // four nine big
    };
  },
  methods: {
    // 抛出发送消息的内容
    sendSocketMsg(data) {
      this.$emit('sendSocketMsg', data);
    },
    // 点击右上角隐藏的时候同步showChat状态
    cancelHandle() {
      this.showChat = false;
    },
    // 抛出选中一个4宫格事件的data
    addLiveList(item) {
      if (this.pageType == 'four') {
        this.$emit('chooseOneLive', item);
      }
    },
    // // 打开聊天框
    // openChat(item) {
    //   console.log(item);
    //   this.liveInfo = item;
    //   this.showChat = true;
    //   item.hadNewMsg = 0;
    // },
    // 抛出改变 4 9 大屏的类型
    setPageState(type) {
      if (type !== 'all') {
        this.pageType = type;
      }
      this.$emit('changePageState', type);
    },
    getCircleStyles(item) {
      return {
        text: item.isWatching ? '正在观看' : '空闲',
        css: {
          color: item.isWatching ? '#61D76B' : '#ECD500',
        },
      };
    },
    // //团队监控列表
    // changeTeamData(value, selectedData) {
    //   this.$emit('initData', value[value.length - 1]);
    // },
  },
};
</script>

<style lang="scss" scoped>
.live-lists {
  width: 100%;
  height: 100%;
  display: flex;
  background: $xf_hui5;
  .control {
    // flex-shrink: 0;
    width: 0.49rem;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
    margin-bottom: 0.14rem;
    border-right: 1px solid $black;
    .nine {
      width: 0.29rem;
      height: 0.29rem;
      background: $xf_hui7;
      padding-top: 0.07rem;
      margin: 0.24rem auto 0;
      .img {
        height: 0.15rem;
        margin: 0 auto;
      }
    }
    .nine:hover {
      background: $xf_hui5_hover;
    }
    .remote {
      span {
        &:first-child {
          margin-right: 0.15rem;
        }
      }
    }
    .select {
      background-color: #151515;
    }
  }
  .right-box {
    width: 2.84rem;
    .no-data {
      text-align: center;
      color: $font_color_1;
      font-size: 0.13rem;
      img {
        width: 1.48rem;
        display: block;
        margin: 0.8rem auto 0.34rem;
      }
    }
    .toggle.blur > div {
      border-radius: 0.16rem;
    }
    .toggle {
      display: flex;
      justify-content: space-between;
      padding: 0.2rem;
      border-bottom: 1px solid $black;
      // background-color: #f0f0f0;
      > div {
        color: $white;
        width: 1.12rem;
        height: 0.32rem;
        line-height: 0.32rem;
        font-size: 0.13rem;
        text-align: center;
        background-color: $xf_hui5;
        letter-spacing: 5px;
        cursor: pointer;
        border: 1px solid #777;
      }
      .active {
        background: $blue;
        color: #fff;
        border: 1px solid $blue;
      }
      .active:hover {
        background: $blue_hover;
      }
      .disactive:hover {
        background: $xf_hui5_hover;
      }
    }

    .list {
      flex: 1;
      padding: 0.2rem;
      overflow-y: auto;
      overflow-x: hidden;
      .item {
        width: 2.44rem;
        height: 0.62rem;
        background-color: $xf_hui5;
        padding: 0.11rem 0 0.11rem 0.14rem;
        margin: 0 auto 0.1rem;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        .left {
          font-size: 0.16rem;
          color: $white;
          display: flex;
          align-items: center;
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 0.22rem;
          img {
            width: 100%;
          }
        }
        .right {
          width: 1.9rem;
          color: $font_color_1;
          font-size: 0.14rem;

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          span {
            font-size: 0.12rem;
          }
        }

        // .msg-right {
        //   margin-left: 10px;
        //   position: relative;
        //   img {
        //     width: 34px;
        //     height: 34px;
        //   }
        //   .red-msg {
        //     position: absolute;
        //     top: 0;
        //     right: 6px;
        //     width:10px;
        //     height: 10px;
        //     background: rgba(255, 70, 70, 1);
        //     border-radius: 50%;
        //   }
        // }
      }
      .blur {
        border-radius: 0.31rem;
        background-color: rgba(0, 0, 0, 0.3);
        filter: drop-shadow(2px 4px 6px black);
      }
      .item:hover {
        background-color: $xf_hui5_hover;
      }
    }
  }
}
</style>
